import React from 'react';
import { bool, func, object, oneOfType, string } from 'prop-types';
import { Box, Flex, Icon, Text, Th, Tooltip } from '@chakra-ui/react';
import {
  IoAlertCircle,
  IoChevronDownOutline,
  IoChevronUpOutline,
} from 'react-icons/io5';
import { ORDER_DIRECTION } from 'utils/constants';
import i18n from 'lib/i18n';
import { tType } from 'types';

const { withTranslation } = i18n;

const ColumnHeader = ({
  value,
  icon,
  backgroundColor,
  iconTransform,
  onClick,
  orderDirection,
  sortable,
  tooltipMessage,
  t,
  ...props
}) => (
  <Th
    pl='2'
    backgroundColor={backgroundColor}
    {...(sortable && {
      onClick,
    })}
    cursor={sortable && 'pointer'}
  >
    <Flex alignItems='center' {...props}>
      {orderDirection && (
        <Box mr='0.5'>
          <Icon
            boxSize={4}
            as={
              orderDirection === ORDER_DIRECTION.ASCENDENT
                ? IoChevronUpOutline
                : IoChevronDownOutline
            }
          />
        </Box>
      )}
      {icon && (
        <Box h='28px' w='15px'>
          <Icon as={icon} boxSize='5' transform={iconTransform} />
        </Box>
      )}
      <Text variant='headingXs' pl='2'>
        {value}
      </Text>
      {tooltipMessage && (
        <Box h='20px' w='15px'>
          <Tooltip placement='bottom' label={t(tooltipMessage)}>
            <span
              style={{
                marginBottom: '12px',
                marginTop: 'auto',
              }}
            >
              <Icon as={IoAlertCircle} boxSize='5' transform={iconTransform} />
            </span>
          </Tooltip>
        </Box>
      )}
    </Flex>
  </Th>
);

ColumnHeader.propTypes = {
  value: string,
  icon: oneOfType([func, object]),
  backgroundColor: string,
  iconTransform: string,
  onClick: func,
  orderDirection: string,
  sortable: bool,
  tooltipMessage: string,
  t: tType,
};

ColumnHeader.defaultProps = {
  sortable: false,
};

export default withTranslation()(ColumnHeader);

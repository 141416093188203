import moment from 'moment';

import i18n from 'lib/i18n';
import { DATE_TYPE, INVALID_DATE_STRING } from 'utils/constants';

/**
 * Format a date and keep only `hh:mm:ss`
 *
 * @param {string} current date
 *
 * @returns {string} the date given formatted with `hh:mm:ss`.
 */

export const formatTime = time => time.toJSON().slice(11, 19);

export const formatDate = (date, type) => {
  if (date === null) {
    return null;
  }

  const {
    i18n: { language },
  } = i18n;

  switch (type) {
    case DATE_TYPE.RELATIVE:
      return moment(date).fromNow();
    case DATE_TYPE.LONG:
      return new Date(date).toLocaleString(language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      });
    case DATE_TYPE.MEDIUM:
    default:
      return new Date(date).toLocaleString(language, {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      });
  }
};

export const dateIsValid = date => date.toString() !== INVALID_DATE_STRING;

export const dateDiff = ({ date, type }) => moment().diff(date, type);

export const monthsDiff = date => dateDiff({ date, type: 'months' });

export const yearsDiff = date => dateDiff({ date, type: 'years' });

export const HourMinuteStringToDate = string => {
  if (!string) {
    return null;
  }

  const date = new Date();
  let hours = parseInt(string.substring(0, 2), 10);
  const minutes = parseInt(string.substring(3, 5), 10);
  const ampm = string.substring(6, 9);

  if (ampm === 'PM') {
    hours += 12;
  }
  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);
  return date;
};

export const getPreviousMonthOfDate = date => {
  const {
    i18n: { language },
  } = i18n;

  date.setMonth(date.getMonth() - 1);

  return date.toLocaleString(language, { month: 'long' });
};

export const getMonthName = month => {
  const {
    i18n: { language },
  } = i18n;

  const date = new Date(0, month, 0);

  return date.toLocaleString(language, { month: 'long' });
};

import {
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'components/DatePicker';
import startcase from 'lodash.startcase';
import capitalize from 'lodash.capitalize';
import i18n from 'lib/i18n';
import { tType } from 'types';
import Pagination from './Pagination';

const { withTranslation } = i18n;

const Filters = ({
  description,
  complementaryData,
  pagination,
  datePickers,
  radioButtonTypes,
  clearFilters,
  t,
}) => {
  return (
    <Flex alignItems='center' flexWrap='wrap'>
      {description && (
        <Box>
          <Text p='6' variant='headingMd'>
            {description}
          </Text>
        </Box>
      )}
      {complementaryData && (
        <Flex alignItems='center'>
          <Text py='6' px='3' variant='textSm'>
            {complementaryData.title}
          </Text>
          <Text py='6' px='3' variant='headingLg'>
            {complementaryData.data}
          </Text>
        </Flex>
      )}
      {datePickers &&
        datePickers.map(datePicker => (
          <Box mr='5' key={datePicker.selectedDate} w={datePicker.w}>
            <DatePicker
              handleDateChange={datePicker.handleDateChange}
              selectedDate={datePicker.selectedDate}
              mode={datePicker.mode}
              maxDate={datePicker.maxDate}
              minDate={datePicker.minDate}
              format={datePicker.format}
            />
          </Box>
        ))}
      {radioButtonTypes && (
        <RadioGroup
          onChange={radioButtonTypes.setValue}
          value={radioButtonTypes.value}
        >
          <Flex direction={radioButtonTypes.direction}>
            {radioButtonTypes.items.map(type => (
              <Radio
                mr='3'
                value={type.value}
                key={type.value}
                data-testid='radio-button'
              >
                {startcase(type.name)}
              </Radio>
            ))}
          </Flex>
        </RadioGroup>
      )}
      {pagination && (
        <>
          <Box ml='auto'>
            <Pagination
              page={pagination.page}
              perPage={pagination.perPage}
              totalPages={pagination.totalPages}
              changePage={pagination.changePage}
              totalCount={pagination.totalCount}
            />
          </Box>

          {pagination.perPageValues && (
            <Box mr='3'>
              <Tooltip label={t('items_per_page')}>
                <Select
                  value={pagination.perPage}
                  onChange={({ target: { value } }) => {
                    pagination.handlePagination(value);
                  }}
                >
                  {pagination.perPageValues.map(value => (
                    <option>{value}</option>
                  ))}
                </Select>
              </Tooltip>
            </Box>
          )}
        </>
      )}
      {clearFilters && (
        <Button onClick={clearFilters} variant='outline' ml='auto'>
          {capitalize(t('clear_filters'))}
        </Button>
      )}
    </Flex>
  );
};

Filters.propTypes = {
  description: PropTypes.string,
  datePickers: PropTypes.arrayOf(
    PropTypes.shape({
      selectedDate: PropTypes.instanceOf(Date),
      handleDateChange: PropTypes.func,
      mode: PropTypes.string,
      maxDate: PropTypes.instanceOf(Date),
      format: PropTypes.string,
      w: PropTypes.string,
    }),
  ),
  pagination: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    totalPages: PropTypes.number,
    changePage: PropTypes.func,
    totalCount: PropTypes.number,
    handlePagination: PropTypes.func,
    perPageValues: PropTypes.array,
  }),
  complementaryData: PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.string,
  }),
  radioButtonTypes: PropTypes.shape({
    setValue: PropTypes.func,
    value: PropTypes.string,
    direction: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }),
  clearFilters: PropTypes.func,
  t: tType,
};

export default withTranslation()(Filters);

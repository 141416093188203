import { Flex, Icon, Text } from '@chakra-ui/react';
import { IoChevronForwardOutline, IoChevronBack } from 'react-icons/io5';
import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({ page, perPage, totalPages, changePage, totalCount }) => {
  const displayedRange = `${page * perPage - (perPage - 1)}-${
    page * perPage > totalCount ? totalCount : page * perPage
  } of ${totalCount}`;
  const handlePageChange = (pageNumber, isDisabled) => {
    if (!isDisabled) {
      changePage(pageNumber);
    }
  };

  return (
    <Flex direction='row' alignItems='center' padding='6'>
      <Text variant='textSm' mx='6'>
        {displayedRange}
      </Text>
      <Icon
        data-testid='chevron-back'
        mr='3'
        cursor='pointer'
        as={IoChevronBack}
        disabled={page === 1}
        onClick={() => handlePageChange(page - 1, page === 1)}
        boxSize='5'
        color={page === 1 ? 'gray.300' : 'gray.500'}
      />
      <Icon
        data-testid='chevron-forward'
        ml='3'
        cursor='pointer'
        as={IoChevronForwardOutline}
        disabled={page === totalPages}
        onClick={() => handlePageChange(page + 1, page === totalPages)}
        boxSize='5'
        color={page === totalPages ? 'gray.300' : 'gray.500'}
      />
    </Flex>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  perPage: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func,
  totalCount: PropTypes.number,
};

export default Pagination;

import { Icon, Text, Flex } from '@chakra-ui/react';
import { node, string } from 'prop-types';
import React from 'react';

const EmptyState = ({ icon, description }) => {
  return (
    <Flex
      direction='column'
      alignItems='center'
      justifyContent='center'
      height='100%'
    >
      <Flex
        width='152px'
        height='152px'
        backgroundColor='alpha.50'
        borderRadius='76px'
        alignItems='center'
        justifyContent='center'
      >
        <Icon width='88px' height='88px' color='gray.400' as={icon} />
      </Flex>
      <Text mt={4} variant='headingMd' color='gray.400' textAlign='center'>
        {description}
      </Text>
    </Flex>
  );
};

EmptyState.propTypes = {
  icon: node,
  description: string,
};

export default EmptyState;

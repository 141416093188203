import { Box, Flex, Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { IoCalendar } from 'react-icons/io5';
import { tType } from 'types';
import i18n from 'lib/i18n';
import { capitalize } from '@material-ui/core';

const { withTranslation } = i18n;

const DatePicker = ({
  t,
  selectedDate,
  handleDateChange,
  maxDate,
  minDate,
  format,
  mode,
}) => {
  return (
    <Box data-testid='date-picker'>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Flex>
          <KeyboardDatePicker
            format={format}
            placeholder={capitalize(t('select_date'))}
            variant='inline'
            inputVariant='outlined'
            openTo={mode === 'daily' ? 'date' : 'year'}
            views={
              mode === 'daily' ? ['year', 'month', 'date'] : ['year', 'month']
            }
            value={selectedDate}
            onMonthChange={date => {
              if (mode === 'monthly') {
                handleDateChange(date);
              }
            }}
            onChange={date => {
              if (mode === 'daily') {
                handleDateChange(date);
              }
            }}
            minDate={minDate}
            maxDate={maxDate}
            InputProps={{ readOnly: true }}
            keyboardIcon={
              <Icon as={IoCalendar} data-testid='calendar-icon' color='black' />
            }
            autoOk
          />
        </Flex>
      </MuiPickersUtilsProvider>
    </Box>
  );
};

DatePicker.propTypes = {
  t: tType,
  selectedDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func,
  format: PropTypes.string,
  mode: PropTypes.string,
};

export default withTranslation()(DatePicker);
